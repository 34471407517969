
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import Menu from "components/menu/Menu";
import MenuItem from "components/menu/MenuItem";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { TenantService } from "services/TenantService"
import { Tenant } from "models/Tenant"
import { BadRequestError } from "errors/ApplicationErrors"
import { Bootstrapper } from "Bootstrapper"
import LoadingButton from "components/buttons/LoadingButton"
import { DialogUtil } from "utils/DialogUtil"
import Article, { Icon2, Section2, Stack2, Subtitle2, Text2 } from "../../Article"
import InfoLink, { InfoType } from "views/articles/shared/InfoLink"
import SimpleGrid, { SimpleContent } from "views/articles/shared/simpleGrid/SimpleGrid"
// import toolbarImg from "../media/exhibit-pane-toolbar.PNG";
const toolbarImg = require('../../media/exhibit-pane-toolbar.PNG').default;
const fileSizeImg = require('../../media/included-exhibits-input.PNG').default;
const bundleMethodImg = require('../../media/insert-position-input.PNG').default;

const PackageMaterialsArticle = (props: any) => {
    const navigate = useNavigate();
    const prodInfoService = Bootstrapper.getInstance<ProductInfoService>(ProductInfoService);

    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogUtils = new DialogUtil();

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const media = prodInfoService.getMedia();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    useEffect(() => {
    }, []);




    return(
        <Article 
            title="How to package your exhibits"
            >
                <Section2>
                    <Stack2>
                        <Text2>Packaging your exhibits involves selecting which exhibits to include in your digital binder; and deciding where those exhibits should appear when combining your materials.</Text2>
                        <Text2>For example, with an affidavit you may want to include only the exhibits that you refer to within your document. On the other hand, you may want to include additional exhibits for supplementary purposes.</Text2>
                        <Text2>Deciding where your collection of exhibits is located within your digital binder is important when you want a backpage to appear as the very last page.</Text2>
                    </Stack2>
                </Section2>
                <Section2>
                    <Stack2>
                        <Box>
                        <SimpleGrid>
                            <SimpleContent>
                                <Stack2>
                                    <Stack2>
                                    <Subtitle2>Included Exhibits</Subtitle2>
                                    <Text2>Open the <InfoLink type={InfoType.PublishPane}/> and expand the <strong>Exhibits</strong> group to display options for including exhibits. You can choose "All Exhibits", "Referenced Exhibits Only", or "Custom".</Text2>
                                    <Text2>One caveat is that these settings are not available if you insert a table of contents (TOC) into your Word document. In that case, the exhibits included in your digital binder must be the same exhibits in your TOC.</Text2>
                                    </Stack2>
                                </Stack2>

                            </SimpleContent>
                            <SimpleContent>
                                <Paper>
                                    <img src={fileSizeImg} />
                                </Paper>
                            </SimpleContent>
                        </SimpleGrid>

                        </Box>
                    </Stack2>

                </Section2>
                <Section2>
                <Box>
                        <SimpleGrid>
                            <SimpleContent>
                                <Stack2>
                                    <Stack2>
                                    <Subtitle2>Location of Exhibits</Subtitle2>
                                    <Text2>When combing your materials, it's important to know where your collection of exhibits will be located within your digital binder. Often you'll want them to simply appear after the last page of your Word document.</Text2>
                                    <Text2>However, if your Word document contains a backpage you'll want to combine your materials such that your collection of exhibits appears before the last page of your Word document. This will ensure that your backpage remains the very last page of your digital binder.</Text2>
                                    <Text2>Choose where your collection of exhibits appears in your digital binder by opening the <InfoLink type={InfoType.PublishPane}/> and expanding the <strong>Appendix</strong> group. The "Insert Position" input contains the following options "After last page", "Before last page", or "Custom" to include the exhibits at a specific page of your Word document.</Text2>
                                    </Stack2>
                                </Stack2>

                            </SimpleContent>
                            <SimpleContent>
                                <Paper>
                                    <img src={bundleMethodImg} />
                                </Paper>
                            </SimpleContent>
                        </SimpleGrid>

                        </Box>

                </Section2>

        </Article>
    )


}


export default PackageMaterialsArticle